export const initRootFontSize = function (doc_01 = document, win_01 = window) {
  var docEl_01 = doc_01.documentElement;
  var resizeEvt_01 =
    "orientationchange" in window ? "orientationchange" : "resize";
  var recalc_01 = function () {
    var clientWidth_01 = docEl_01.clientWidth;
    if (!clientWidth_01) return;
    if (clientWidth_01 < 750) {
      docEl_01.style.fontSize =
        ((clientWidth_01 / 750) * 100).toFixed(1) + "px";
    } else {
      docEl_01.style.fontSize = 100 + "px";
    }
  };
  recalc_01();
  if (!doc_01.addEventListener) return;
  win_01.addEventListener(resizeEvt_01, recalc_01, false);
};
