import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
/* Layout */
// import Layout from '@/layout'

// 公共路由
export const constantRoutes = [
  {
    path: "/",
    component: () => import("../views/index"),
    hidden: true,
  },
  {
    path: "/ok",
    component: () => import("../views/reservationOk"),
    hidden: true,
  },
];

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    mode: "history", // 后端支持可开
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();

router.beforeEach((to, from, next) => {
  // 处理jssdk签名,兼容history模式
  if (window.__wxjs_is_wkwebview === true) {
    next();
  } else {
    next();
  }
});
// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
// export function resetRouter() {
//   const newRouter = createRouter()
//   router.matcher = newRouter.matcher // reset router
// }

export default router;
